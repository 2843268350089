.link {
  border: 2px solid var(--primary-color);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
}

@include respond-up('large') {
  .link {
    transition: background-color, color;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);

    &:hover {
      color: white;
      background-color: var(--primary-color);
    }
  }
}

@include respond-up('s-medium') {
  .link {
    padding: 4px 10px;
  }
}

@include respond-down('small') {
  .link {
    padding: 6px 12px;
  }
}