.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  position: relative;

  &__logo-wrap {
    position: absolute;
    top: var(--grid-gap);
    left: 0;
  }

  &__code {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    text-transform: uppercase;
    color: white;
    font-stretch: 90%;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 60;
  }
}

@include respond-up('large') {
  .error-page {
    &__code {
      font-size: 330px;
    }
  }
}

@include respond('medium') {
  .error-page {
    &__code {
      font-size: 230px;
    }
  }
}

@include respond('s-medium') {
  .error-page {
    &__code {
      font-size: 230px;
    }
  }
}

@include respond-up('s-medium') {
  .error-page {
    &__description {
      margin-top: 15px;
    }

    &__button {
      margin-top: 33px;
    }
  }
}

@include respond-down('small') {
  .error-page {
    &__code {
      font-size: 130px;
    }

    &__button {
      margin-top: 22px;
    }
  }
}