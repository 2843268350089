:root {
  // Максимальная ширина строки (строка = контейнер со спейсерами)
  --max-row-width: 2560px;

  // Расстояние между колонками - задается динамически по разрешениям - смотри _container.scss
  --grid-gap: 1px;

  // Отступ от края экрана до строки (строка = контейнер со спейсерами)
  --grid-indent: 0px;

  // Отступ от края строки до колонок (строка = контейнер со спейсерами)
  --grid-spacer: 1px;

  // Отступ от края контейнера до колонок
  --grid-spacer-and-indent: calc(var(--grid-indent) + var(--grid-spacer));

  // Кол-во колонок
  --grid-columns-number: 1;

  // Ширина одной колонки - задается динамически по разрешениям - смотри _container.scss
  --grid-column: calc((var(--vw, 1vw) * 100 - 2 * var(--grid-spacer-and-indent) - (var(--grid-columns-number) - 1) * var(--grid-gap)) / var(--grid-columns-number));

  // Ширина колонки
  --grid-column1: var(--grid-column);
  --grid-column2: calc((var(--grid-column) * 2) + var(--grid-gap));
  --grid-column3: calc((var(--grid-column) * 3) + (var(--grid-gap) * 2));
  --grid-column4: calc((var(--grid-column) * 4) + (var(--grid-gap) * 3));
  //--grid-column5: calc((var(--grid-column) * 5) + (var(--grid-gap) * 4));
  //--grid-column6: calc((var(--grid-column) * 6) + (var(--grid-gap) * 5));
  //--grid-column7: calc((var(--grid-column) * 7) + (var(--grid-gap) * 6));
  //--grid-column8: calc((var(--grid-column) * 8) + (var(--grid-gap) * 7));
  //--grid-column9: calc((var(--grid-column) * 9) + (var(--grid-gap) * 8));
  //--grid-column10: calc((var(--grid-column) * 10) + (var(--grid-gap) * 9));
  //--grid-column11: calc((var(--grid-column) * 11) + (var(--grid-gap) * 10));
  //--grid-column12: calc((var(--grid-column) * 12) + (var(--grid-gap) * 11));
  //--grid-column13: calc((var(--grid-column) * 13) + (var(--grid-gap) * 12));
  //--grid-column14: calc((var(--grid-column) * 14) + (var(--grid-gap) * 13));
}

@include respond-up('medium') {
  :root {
    --grid-columns-number: 3;
  }
}

@include respond-up('s-medium') {
  :root {
    --grid-gap: 20px;
    --grid-spacer: 20px;
  }
}

@include respond('s-medium') {
  :root {
    --grid-columns-number: 4;
  }
}

@include respond-down('small') {
  :root {
    --grid-gap: 10px;
    --grid-spacer: 10px;
    --grid-columns-number: 2;
  }
}
