.text {
  &__error-page,
  &__body {
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
    font-size: var(--body-font-size);
    line-height: 110%;
    color: var(--grey-color);
    font-stretch: 110%;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
  }

  &__heading {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 600;
    font-size: var(--heading-font-size);
    line-height: 110%;
    text-transform: uppercase;
    color: var(--primary-color);
    font-stretch: 90%;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 60;

    &_white {
      color: white;
    }
  }

  &__link {
    font-family: var(--h-font);
    font-style: normal;
    font-weight: 600;
    font-size: var(--link-font-size);
    line-height: 110%;
    text-transform: uppercase;
    color: var(--primary-color);
    font-stretch: 90%;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 60;
  }

  &__description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    font-stretch: 90%;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    color: var(--grey-color);
  }
}

@include respond-up("large") {
  .text {
    &__error-page,
    &__body {
      --body-font-size: calc(var(--vw, 1vw) * 100 * 22 / 1920);
    }

    &__heading {
      --heading-font-size: calc(var(--vw, 1vw) * 100 * 40 / 1920);
    }

    &__link {
      --link-font-size: 20px;
    }
  }
}

@include respond-between("s-medium", "medium") {
  .text {
    &__error-page,
    &__body {
      --body-font-size: 22px;
    }

    &__heading {
      --heading-font-size: 30px;
    }

    &__link {
      --link-font-size: 20px;
    }
  }
}

@include respond-down("small") {
  .text {
    &__error-page,
    &__body {
      --body-font-size: 18px;
    }

    &__heading {
      --heading-font-size: 25px;
    }

    &__link {
      --link-font-size: 16px;
    }

    &__description {
      font-size: 14px;
      line-height: 130%;
    }
  }
}