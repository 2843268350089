.social-link {
  --link-size: 41px;
  background: var(--background-color);
  width: var(--link-size);
  height: var(--link-size);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
}

@include respond-up('large') {
  .social-link {
    svg {
      path, ellipse {
        transition: fill;
        transition-duration: var(--default-timing);
        transition-timing-function: var(--default-transition-function);
      }
    }

    &:hover {
      svg {
        path {
          fill: white;
        }

        ellipse {
          fill: var(--primary-color);
        }
      }


      //color: white;
      //background-color: var(--primary-color);
    }
  }
}
