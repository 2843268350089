@include respond-up('s-medium') {
  .index-page {
    &.need-animation {
      .block {
        transform: translate3d(0, 30px, 0);
        opacity: 0;

        &__index::before {
          transform: translate3d(100%, 0, 0);
        }
      }
    }
  }
}

@include respond-down('small') {
  .index-page {
    &.need-animation {
      .block {
        transform: translate3d(0, 30px, 0);
        opacity: 0;

        &__text-container {
          opacity: 0;
        }

        &__wrap-links {
          opacity: 0;
          transform: translate3d(0, -10px, 0);
        }

        &__index {
          bottom: 50%;
          transform: translateY(50%);
        }

        &__picture-wrap {
          height: 0;
          opacity: 0;
        }

        &__picture-title,
        &__index {
          opacity: 0;
        }

        &__wrap-bottom {
          opacity: 0;
          transform: translate3d(0, -40px, 0);
        }

        &__logo-wrap {
          width: 49px;
          height: 56px;
        }

        &__link-title-wrap,
        &__description {
          opacity: 0;
          transform: translate3d(0, -50px, 0);
        }

        &__title {
          opacity: 0;
          height: 100%;
        }

        &__initial-title {
          opacity: 1;
          height: auto;
        }
      }
    }

    &.animate {
      .block {
        animation: height-in 4.2s var(--teaser-transition-func);

        @for $i from 1 through 15 {
          &:nth-child(#{$i}) {
            animation-delay: calc(#{$i} * 0.05s);
          }
        }
      }
    }
  }
}

@keyframes height-in {
  from {
    height: 98px;
  }
  84% {
    height: 98px;
  }
  to {
    height: calc(368 / 354 * var(--grid-column2));
  }
}