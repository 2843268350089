.block {
  background: white;
  position: relative;
  overflow: hidden;
  transition: opacity, transform;
  transition-duration: .7s;
  transition-delay: 1.8s;
  transition-timing-function: var(--teaser-transition-func);
  -webkit-transition-property: opacity, transform;
  -webkit-transition-duration: .7s;
  -webkit-transition-delay: 1.8s;
  -webkit-transition-timing-function: var(--teaser-transition-func);

  @for $i from 1 through 15 {
    &:nth-child(#{$i}) {
      transition-delay: calc(1.8s + #{$i} * 0.05s);
      -webkit-transition-delay: calc(1.8s + #{$i} * 0.05s);

      .block__index::before {
        transition-delay: calc(2.1s + #{$i} * 0.05s);
        -webkit-transition-delay: calc(2.1s + #{$i} * 0.05s);
      }
    }
  }

  &__picture-wrapper {
    width: 100%;
    height: 100%;
  }

  &__wrap,
  &__wrap-top,
  &__wrap-links,
  &__wrap-bottom,
  &__wrap-social-links {
    display: flex;
  }

  &__wrap,
  &__wrap-bottom,
  &__wrap-top {
    justify-content: space-between;
  }

  &__wrap {
    flex-direction: column;
    min-height: 100%;
    flex: 0;
  }

  &__text-container {
    z-index: 30;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
  }

  &__index {
    display: block;
    position: absolute;
    z-index: 10;
    right: var(--grid-gap);
    overflow: hidden;

    &::before {
      content: counter(section);
      display: block;
      font-family: var(--h-font);
      font-style: normal;
      font-weight: 600;
      font-size: var(--number-font-size);
      line-height: 110%;
      text-transform: uppercase;
      color: var(--background-color);
      font-stretch: 90%;
      font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 60;
      transition: transform .7s var(--teaser-transition-func);
      -webkit-transition: transform .7s var(--teaser-transition-func);
    }
  }

  &__description {
    display: block;
  }

  &__picture-wrap {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__picture {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(47, 52, 98, 0.6), rgba(47, 52, 98, 0.6));
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__link-title {
    display: flex;
    align-items: center;
  }

  &__link-title-icon {
    display: flex;
    height: max-content;
    margin-left: 16px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__link-title-wrap {
    display: flex;
    margin-top: auto;
  }

  &_number {
    position: relative;
    overflow: hidden;
    display: flex;
    counter-increment: section;
  }

  &__logo-wrap {
    display: flex;

    svg {
      width: 100%;
      height: auto;
    }
  }
}

@include respond-up("large") {
  .block {
    border-radius: 20px;
    overflow: hidden;
    min-height: calc(367 / 613 * var(--grid-column));

    &_number {
      --number-font-size: calc(var(--vw, 1vw) * 100 * 150 / 1920);

      &:hover {
        .block {
          &__picture {
            transform: scale(1);
          }

          &__second-title,
          &__picture-title,
          &__picture-wrap,
          &__link-title-wrap,
          &__description {
            opacity: 1;
          }

          &__index,
          &__initial-title {
            opacity: 0;
          }
        }
      }
    }

    &__picture {
      overflow: hidden;
      border-radius: 20px;
      transform: scale(1.09);
      transition: transform var(--default-timing) var(--default-transition-function);
      -webkit-transition: transform var(--default-timing) var(--default-transition-function);
    }

    &__title {
      opacity: 0;
      transition: opacity, color;
      transition-duration: var(--default-timing);
      transition-timing-function: var(--default-transition-function);
      -webkit-transition-property: opacity, color;
      -webkit-transition-duration: var(--default-timing);
      -webkit-transition-timing-function: var(--teaser-transition-func);
    }

    &__title,
    &__description {
      max-width: calc((var(--grid-column1) - 60px) * .69);
    }

    &__initial-title {
      position: absolute;
      top: 30px;
      left: 30px;
      opacity: 1;
      z-index: 10;
    }

    &__picture-wrapper {
      position: relative;
      border-radius: 20px;
      overflow: hidden;

      .block__picture-title {
        position: absolute;
      }
    }

    &__text-container {
      padding: 30px 30px 35px;
    }

    &__index {
      bottom: 7px;
      transition: opacity var(--default-timing) var(--default-transition-function);
      -webkit-transition: opacity var(--default-timing) var(--default-transition-function);
    }

    &__picture-wrap {
      overflow: hidden;
      border-radius: 20px;
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-transition-function);
      -webkit-transition: opacity var(--default-timing) var(--default-transition-function);
    }

    &__picture-title {
      &_tablet {
        display: none;
      }

      opacity: 0;
      transition: opacity var(--default-timing) var(--default-transition-function);
      -webkit-transition: opacity var(--default-timing) var(--default-transition-function);
    }

    &__link-title-wrap {
      justify-content: flex-end;
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-transition-function);
      -webkit-transition: opacity var(--default-timing) var(--default-transition-function);
    }

    &__description {
      margin-top: 40px;
      color: white;
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-transition-function);
      -webkit-transition: opacity var(--default-timing) var(--default-transition-function);
    }

    &__link-title-text {
      color: white;
    }

    &__wrap-bottom {
      align-items: flex-end;
    }

    &__bottom-text {
      flex: 0 1 65%;
    }

    &__wrap-links {
      > * {
        margin-left: 8px;
      }
    }

    &__logo-wrap {
      width: clamp(60px, calc(98 / 1920 * var(--vw, 1vw) * 100), 98px);
    }

    &__wrap {
      padding: 30px;
    }
  }
}

@include respond-up("medium") {
  .block {
    grid-column: span 1;

    &__double-large {
      grid-column: span 2;
    }

    &__double-large-row {
      grid-row: span 2;
    }
  }
}

@include respond("medium") {
  .block {
    height: calc(471 / 307 * var(--grid-column));

    &__text-container {
      padding: 20px;
    }

    &__index {
      display: none;
    }

    &__picture-wrap {
      height: calc(var(--grid-column) * (209 / 307));
    }

    &__picture-title {
      right: 20px;
    }

    &__logo-wrap {
      width: 89px;
    }

    &__wrap-bottom {
      flex-direction: column;
      flex: 0 0 210px;
    }

    &__title {
      display: none;
    }

    &__initial-title {
      display: none;
    }
  }
}

@include respond-down("medium") {
  .block {
    border-radius: 15px;

    &__text-container {
      flex: 0 1 100%;
    }

    &__link-title-text {
      color: var(--primary-color);
    }

    &__link-title-icon {
      svg path {
        stroke: var(--primary-color);
      }
    }

    &__picture-wrap {
      position: relative;
      overflow: hidden;
      flex: 0 0 auto;
    }

    &__picture-title {
      position: absolute;
      bottom: 20px;
      left: 20px;
      color: white;
    }

    &__wrap-links {
      flex-direction: column;
      align-items: flex-end;

      :nth-child(2) {
        margin-top: 10px;
      }
    }

    &_number {
      --number-font-size: 50px;
      flex-direction: column;
    }

    &__wrap {
      padding: 20px;
    }
  }
}

@include respond-up("s-medium") {
  .block {
    &__wrap-social-links {
      :nth-child(2) {
        margin-left: 10px;
      }
    }

    &__scroll-link {
      display: none;
    }

    &__link-title-icon {
      width: 20px;
      height: 18px;
    }
  }
}

@include respond("s-medium") {
  .block {
    //min-height: calc(503 / 387 * var(--grid-column2));
    min-height: 503px;

    &__initial-title {
      display: none;
    }

    &__link-title-wrap {
      padding-top: calc((112 / 834) * 100vw);
    }

    &__text-container {
      padding: 20px 80px 20px 20px;
    }

    &__index {
      bottom: 14px;
    }

    &__wrap-bottom {
      flex-direction: column;
      flex: 0 0 190px;
    }

    &__picture-title {
      right: 80px;
    }

    &__logo-wrap {
      width: 89px;
    }

    &__title {
      display: none;
    }
  }
}

@include respond-down("s-medium") {
  .block {
    grid-column: span 2;
    height: 100%;

    &__double-medium {
      grid-column: span 4;

      .block__bottom-text {
        max-width: calc((var(--grid-column4) - 40px) * .69);
      }
    }

    &__picture-wrap {
      border-radius: 15px;
      height: calc(var(--grid-column2) * (209 / 307));
    }
  }
}

@include respond-down("small") {
  .block {
    height: calc(368 / 354 * var(--grid-column2));
    overflow: hidden;

    &_number {
      height: max-content;
    }

    &__picture-wrap {
      transition: opacity, height;
      transition-duration: .7s;
      transition-delay: 3.5s;
      transition-timing-function: var(--default-transition-function);
      -webkit-transition-property: opacity, height;
      -webkit-transition-duration: .7s;
      -webkit-transition-delay: 3.5s;
      -webkit-transition-timing-function: var(--teaser-transition-func);
    }

    &__wrap-links {
      transition: opacity, transform;
      transition-duration: .7s;
      transition-delay: 3.5s;
      transition-timing-function: var(--teaser-transition-func);
      -webkit-transition-property: opacity, transform;
      -webkit-transition-duration: .7s;
      -webkit-transition-delay: 3.5s;
      -webkit-transition-timing-function: var(--teaser-transition-func);
    }

    &__link-title-wrap,
    &__description {
      transition: opacity, transform;
      transition-duration: .4s;
      transition-delay: 3.8s;
      transition-timing-function: var(--teaser-transition-func);
      -webkit-transition-property: opacity, transform;
      -webkit-transition-duration: .4s;
      -webkit-transition-delay: 3.8s;
      -webkit-transition-timing-function: var(--teaser-transition-func);
    }

    &__description {
      opacity: 1;
    }

    &__bottom-text {
      max-width: 74%;
    }

    &__scroll-link {
      margin: -10px;
      padding: 10px;

      svg {
        transform: rotate(90deg);

        path {
          stroke: var(--primary-color);
        }
      }
    }

    &__text-container {
      padding: 15px 100px 18px 20px;
      opacity: 1;
      transition-duration: .7s;
      transition-delay: 9s;
      transition-timing-function: var(--default-transition-function);
      -webkit-transition-property: opacity;
      -webkit-transition-duration: .7s;
      -webkit-transition-delay: 9s;
      -webkit-transition-timing-function: var(--teaser-transition-func);
    }

    &__initial-title {
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      max-width: 50%;
      font-size: 18px;

      transition: opacity;
      transition-duration: .7s;
      transition-delay: 3.5s;
      transition-timing-function: var(--default-transition-function);
      -webkit-transition-property: opacity;
      -webkit-transition-duration: .7s;
      -webkit-transition-delay: 3.5s;
      -webkit-transition-timing-function: var(--teaser-transition-func);
    }

    &__index {
      bottom: 11px;
      transform: none;
      transition: opacity .4s var(--teaser-transition-func) 3.8s, transform .4s var(--teaser-transition-func) 3.8s;
      -webkit-transition: opacity .4s var(--teaser-transition-func) 3.8s, transform .4s var(--teaser-transition-func) 3.8s;
    }

    &__link-title-icon {
      margin-left: 9px;
      width: 13px;
      height: 13px;
    }

    &__picture-title {
      right: 100px;
      transition: opacity .05s var(--teaser-transition-func) 3.5s;
      -webkit-transition: opacity .05s var(--teaser-transition-func) 3.5s;
    }

    &__wrap-social-links {
      align-items: flex-end;

      :nth-child(2) {
        margin-left: 10px;
      }
    }

    &__logo-wrap {
      display: flex;
      width: 62px;
      transition: width, height;
      transition-duration: .7s;
      transition-delay: 3.5s;
      transition-timing-function: var(--teaser-transition-func);
      -webkit-transition-property: width, height;
      -webkit-transition-duration: .7s;
      -webkit-transition-delay: 3.5s;
      -webkit-transition-timing-function: var(--teaser-transition-func);
    }

    &__scroll-link-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__wrap-bottom {
      flex-direction: column;
      flex: 0 0 210px;
      justify-content: space-between;
      transition: opacity, transform;
      transition-duration: .4s;
      transition-delay: 3.8s;
      transition-timing-function: var(--teaser-transition-func);
      -webkit-transition-property: opacity, transform;
      -webkit-transition-duration: .4s;
      -webkit-transition-delay: 3.8s;
      -webkit-transition-timing-function: var(--teaser-transition-func);
    }

    &__link-title-wrap {
      padding-top: calc((57 / 374) * 100vw);
    }

    &__title {
      transition: opacity, color, height;
      color: var(--primary-color);
      height: 0;
      opacity: 0;
      transition-duration: 0.05s;
      transition-delay: 3.5s;
      -webkit-transition-duration: 0.05s;
      -webkit-transition-delay: 3.5s;
    }

    &__wrap-top {
      align-items: flex-start;
    }
  }
}