.preloader {
  display: flex;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color);
  transition: opacity .4s var(--teaser-transition-func) 1.8s;

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: opacity .4s var(--teaser-transition-func) .5s;
  }

  &__main {
    flex: 0 1 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo-wrap {
    display: flex;
  }

  &__logo {
    display: flex;
    width: 98px;
    height: 112px;
    transition: transform .7s .9s var(--teaser-transition-func);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__bottom {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 45px 27px 50px;
  }

  &__title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 110%;
    color: var(--primary-color);
    font-stretch: 110%;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    margin-left: 50px;
    transition: transform, opacity;
    transition-duration: .7s;
    transition-delay: 1.1s;
    transition-timing-function: var(--teaser-transition-func);
  }

  &__logo-line {
    width: 2px;
    height: 110px;
    background-color: var(--primary-color);
    transition: height .7s var(--teaser-transition-func) 1.1s;
    margin-left: 50px;
  }

  &__bottom-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: var(--grey-color);
    font-stretch: 110%;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
  }

  &__line-wrap {
    display: flex;
    align-items: baseline;
  }

  &__line {
    position: relative;
    height: 2px;
    background-color: #C8CCDB;
    width: 286px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: var(--primary-color);
    }
  }

  &__percent-wrap {
    display: flex;
    align-items: baseline;
    margin-left: 25px;
  }

  &__percent-title,
  &__percent-icon {
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    text-transform: uppercase;
    color: var(--primary-color);
    font-stretch: 90%;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 60;
  }

  &__percent-title {
    display: flex;
    position: relative;
    font-size: 100px;
    overflow: hidden;
    height: 110px;
  }

  &__percent-icon {
    font-size: 40px;
    margin-left: 6px;
  }

  &__percent-title-item {
    display: flex;
    flex-direction: column;
  }

  &.need-animation {
    .preloader {
      &__inner {
        opacity: 0;
      }

      &__title {
        opacity: 0;
        transform: translate3d(-20px, 0, 0);
      }

      &__logo-line {
        height: 0;
      }

      &__logo {
        transform: translate3d(167px, 0, 0);
      }
    }
  }

  &.animate {
    opacity: 0;
    pointer-events: none;

    .preloader {
      &__percent-title-item {
        animation: numbers-in 1s var(--teaser-transition-func) forwards;
        animation-delay: .5s;

        &:nth-child(2) {
          animation-delay: .6s;
        }
      }

      &__line {
        &::before {
          animation: line-in 1s cubic-bezier(.93, .15, .25, 1) forwards;
          animation-delay: .5s;
        }
      }
    }
  }
}

@include respond-down('s-medium') {
  .preloader {
    &__bottom {
      justify-content: flex-end;
      padding: 0 10px 5px;
    }

    &__title {
      font-size: 16px;
      margin-left: 25px;
    }

    &__line {
      width: 255px;
    }

    &__percent-wrap {
      margin-left: 22px;
    }

    &__percent-title {
      font-size: 55px;
      height: 61px;
    }

    &__percent-icon {
      font-size: 22px;
      margin-left: 4px;
    }

    &__bottom-title {
      display: none;
    }

    &__logo {
      width: 62px;
      height: 71px;
    }

    &__logo-line {
      height: 71px;
      margin-left: 25px;
    }

    &.need-animation {
      .preloader {
        &__logo {
          transform: translate3d(85px, 0, 0);
        }
      }
    }

    &.animate {
      opacity: 0;
      pointer-events: none;

      .preloader {
        &__percent-title-item {
          animation: mobile-numbers-in 1s var(--teaser-transition-func) forwards;
          animation-delay: .5s;
        }
      }
    }
  }
}

@include respond-down('small') {
  .preloader {

  }
}

@keyframes numbers-in {
  from {
    transform: translate3d(0, 64px, 0);
  }

  to {
    transform: translate3d(0, -220px, 0);
  }
}

@keyframes mobile-numbers-in {
  from {
    transform: translate3d(0, 53px, 0);
  }

  to {
    transform: translate3d(0, -121px, 0);
  }
}

@keyframes line-in {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
